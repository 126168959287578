<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head :page-head="pagehead"></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->

                <div class="page-contents userAccount">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <div class="messages">
                                <h2 class="page__title">
                                    {{ $t('Messages') }}
                                    <span>{{ unreadCount }}</span>
                                </h2>
                                <div class="messages__row">
                                    <div class="messages__list">
                                        <div class="messages__list--search">
                                            <b-input
                                                :placeholder="
                                                    $t('Search by Name')
                                                "
                                                v-model="search_name"
                                                @input="search"
                                            ></b-input>
                                        </div>
                                        <div class="messages__userWrapper">
                                            <user-messages
                                                :users="zainersList"
                                                :selectedChat="selectedChat"
                                                @click="changeChat"
                                            ></user-messages>
                                        </div>
                                    </div>
                                    <div
                                        class="messages__chat"
                                        :class="[
                                            selectedChat
                                                ? ''
                                                : 'messages__chat_hide',
                                        ]"
                                    >
                                        <chat
                                            @sendMessage="sendMessage"
                                            @removeMessage="removeMessage"
                                            @lodaNext="lodaNext"
                                            :messageList="messageList"
                                            :participants="participants"
                                            :loadding="loaddingChat"
                                        ></chat>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/messages-banner.jpg'
import imgLarge from '@/assets/images/banners/messages-banner.jpg'
import imgBig from '@/assets/images/banners/messages-banner.jpg'
import { ChatHelper } from '@/common/crud-helpers/chat'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { UsersHelper } from '@/common/crud-helpers/users'
import { API_BASE, VUE_APP_WS_CHAT } from '@/conf'
import Chat from '@/modules/users/components/messages/Chat.vue'
import UserMessages from '@/modules/users/components/messages/UserMessages.vue'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

export default {
    components: {
        UserMessages,
        Chat,
    },
    data() {
        return {
            unreadCount: 0,
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            banner: '',
            pagehead: {
                title: '',
                shortdes: '',
            },
            search_name: '',
            messageList: [],
            participants: [],
            filters: {
                limit: 99999999,
                offset: 0,
            },
            zainersList: [],
            chatZainers: [],
            selectedChat: null,
            next: '',
            loaddingChat: false,
        }
    },
    async created() {
        let singleUser = ''
        if (this.$route.params.username != 'all') {
            singleUser = this.$route.params.username
        }

        ChatHelper.getChatList().then(() => {
            this.setChatUser(singleUser)
        })

        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setTitleAndDescription()
        }
    },
    methods: {
        sendMessage: function(message) {
            message.author = this.userAuth.id
            message.room_uuid = this.selectedChat.chat_id
            this.selectedChat.connection.send(JSON.stringify(message))
        },
        removeMessage: function(message) {
            let data = {
                author: this.userAuth.id,
                type: 'remove',
                data: {
                    id: message.id,
                },
                room_uuid: message.room_uuid,
            }
            this.selectedChat.connection.send(JSON.stringify(data))
        },
        lodaNext() {
            if (this.next) {
                this.loaddingChat = true
                let url = new URL(API_BASE + this.next)
                let cursor = url.searchParams.get('cursor')
                ChatHelper.getMoreChat({
                    cursor: cursor,
                    uuid: this.selectedChat.chat_id,
                })
            }
        },
        changeChat(user) {
            let existChat = _.find(this.chatZainers, function(z) {
                return z.id == user
            })

            if (existChat) {
                let chat = _.find(this.getChatList.results, function(c) {
                    return c.id == existChat.chat_id
                })
                this.messageList = cloneDeep(chat.last_messages.messages)
                this.messageList.forEach(message => {
                    if (message.author == this.userAuth.id) {
                        message.author = 'me'
                    }
                    message.type = 'text'
                })

                this.participants = cloneDeep(chat.members)
                this.participants.forEach(participant => {
                    if (participant.id == this.userAuth.id) {
                        participant.id = 'me'
                    }
                })
                this.selectedChat = existChat
                this.next = chat.last_messages.next

                let lastMessage = chat.last_messages.messages[0]
                if (lastMessage && lastMessage.author != this.userAuth.id) {
                    let makeRead = {
                        room_uuid: lastMessage.room_uuid,
                        author: lastMessage.author,
                        type: 'make_read',
                        data: { id: lastMessage.id },
                    }
                    this.selectedChat.connection.send(JSON.stringify(makeRead))
                }
            } else {
                const _this = this
                ChatHelper.createChat({
                    members: [_this.userAuth.id, user],
                }).then(resp => {
                    let member = _.find(resp.data.members, function(m) {
                        return m.id != _this.userAuth.id
                    })
                    ChatHelper.getChatList().then(() => {
                        this.setChatUser(member.username)
                    })
                })
            }
        },
        myWebSocket(chat_id) {
            const _this = this
            var token = ''
            if (this.token.length > 0) {
                token = this.token
            }
            let connection = new WebSocket(
                `${VUE_APP_WS_CHAT}${chat_id}/?token=${token}`,
            )
            connection.onmessage = function(rep) {
                if (rep) {
                    ChatHelper.getChatList().then(() => {
                        _this.setChatUser()
                    })
                }
            }
            return connection
        },
        changeFitler: debounce(function(val, event) {
            if (event) event.preventDefault()
            this.zainersList = []
            this.getZainers()
        }, 400),

        search() {
            if (this.search_name && this.search_name.length >= 1) {
                this.filters.search = this.search_name
                this.changeFitler()
            } else {
                this.zainersList = []
                this.setChatUser()
            }
        },
        getZainers() {
            UsersHelper.list(this.filters)
        },
        setChatUser(singleUser) {
            var _this = this
            this.unreadCount = 0
            this.getChatList.results.forEach(data => {
                if (data.unread_count) {
                    this.unreadCount += 1
                }
                let members = _.filter(data.members, function(member) {
                    return member.id != _this.userAuth.id
                })

                if (members.length == 1 && members[0]) {
                    _this.zainersList = _.concat(_this.zainersList, members)
                    members[0].unread_count = data.unread_count
                    let chat = _.find(_this.chatZainers, function(c) {
                        return c.chat_id == data.id
                    })

                    if (!chat) {
                        _this.chatZainers.push({
                            id: members[0].id,
                            chat_id: data.id,
                            connection: _this.myWebSocket(data.id),
                        })
                    }
                } else {
                    console.log('members length' + members.length)
                }
            })
            this.zainersList = _.uniqBy(this.zainersList, function(e) {
                return e.id
            })

            let selected = _this.zainersList.find(function(zainer) {
                return zainer.username == singleUser
            })

            if (selected && selected.id) {
                this.changeChat(selected.id)
            } else if (singleUser) {
                UsersHelper.get(singleUser)
            } else if (this.selectedChat) {
                this.changeChat(this.selectedChat.id)
            }
        },
        setTitleAndDescription() {
            let activeTab = this.dashboardSettings.dashboard_tabs.filter(tab =>
                tab.value.url.includes(this.$route.name),
            )[0]
            this.banner = activeTab.value.banner
            this.pagehead.title = activeTab.value[`title_${this.$i18n.locale}`]
            this.pagehead.shortdes =
                activeTab.value[`description_${this.$i18n.locale}`]
        },
    },
    computed: {
        ...mapGetters([
            'userAuth',
            'getChatList',
            'user',
            'moreChatList',
            'usersList',
            'token',
            'dashboardSettings',
        ]),
    },
    watch: {
        user() {
            this.zainersList.push(this.user)
            this.changeChat(this.user.id)
        },
        moreChatList() {
            this.next = this.moreChatList.next
            let messageList = cloneDeep(this.moreChatList.results)
            messageList.forEach(message => {
                if (message.author == this.userAuth.id) {
                    message.author = 'me'
                }
                message.type = 'text'
            })
            let all = _.concat(this.messageList, messageList)
            this.messageList = cloneDeep(all)
            this.loaddingChat = false
        },
        usersList() {
            const _this = this
            _this.zainersList = []
            let chatMembers = []
            this.getChatList.results.forEach(data => {
                let members = _.filter(data.members, function(member) {
                    return member.id != _this.userAuth.id
                })
                if (members.length == 1 && members[0]) {
                    members[0].unread_count = data.unread_count
                    chatMembers = _.concat(chatMembers, members)
                } else {
                    console.log('members length' + members.length)
                }
            })
            _this.usersList.results.forEach(user => {
                if (user.id !== _this.userAuth.id) {
                    let object = chatMembers.find(m => m.id == user.id)
                    if (object) {
                        this.zainersList.push(object)
                    } else {
                        this.zainersList.push(user)
                    }
                }
            })
        },
        dashboardSettings() {
            this.setTitleAndDescription()
        },
    },
}
</script>

<style lang="scss" scoped>
.messages__chat_hide {
    display: none;
}
.page-contents {
    padding-top: rem(104px);
}
.sidenav-wrapper {
    margin-top: 0;
    /deep/.right-col {
        padding-bottom: 250px;
        @media screen and (max-width: 991px) {
            padding-top: 35px !important;
        }
        .page__title {
            margin: 0 !important;
            display: inline-block;
            position: relative;
            span {
                width: 45px;
                height: 45px;
                border-radius: 100%;
                background: #e4d700;
                @include flex(center, center);
                color: var(--white);
                margin-left: rem(12px);
                position: absolute;
                top: -11px;
                right: -67px;
            }
            &:after {
                width: rem(470px);
            }
            @media screen and (max-width: 1600px) {
                padding-bottom: 33px;
                &:after {
                    width: 250px;
                }
            }
            @media screen and (max-width: 991px) {
                padding-top: 20px;
            }
            @media screen and (max-width: 767px) {
                span {
                    width: 35px;
                    height: 35px;
                    right: -50px;
                }
            }
        }
    }
}
.messages {
    margin-top: rem(40px);
    @media screen and (max-width: 1600px) {
        margin-top: rem(36.8px);
    }
    @media screen and (max-width: 1450px) {
        margin-top: rem(22.4px);
    }
    @media screen and (max-width: 1199px) {
        margin-top: rem(57.6px);
    }
    @media screen and (max-width: 991px) {
        margin-top: 0;
    }
    &__row {
        display: flex;
        padding-left: rem(32px);
        padding-top: rem(60px);
    }
    &__list {
        flex: 0 0 290px;
        max-width: 290px;
        margin-right: rem(30px);
        &--search {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                background-image: url("data:image/svg+xml,%3Csvg fill='%234a1885' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 100%;
                width: 47px;
                height: 45px;
                top: 53%;
                transform: translateY(-50%);
                left: -18px;
                z-index: 2;
            }
            &:after {
                content: '';
                position: absolute;
                height: 78%;
                width: 2px;
                background: #f6f6f6;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
            }
            .form-control {
                font-size: rem(18px);
                color: #4a1885;
                border: 2px solid #4a1885;
                background: transparent;
                box-shadow: none;
                padding-left: 25px;
                &::placeholder {
                    color: #4a1885;
                    font-size: rem(18px);
                }
            }
        }
    }
    &__chat {
        flex: 1;
    }
    &__userWrapper {
        padding-top: rem(12px);
    }
    @media screen and (max-width: 991px) {
        &__row {
            flex-direction: column;
        }
        &__list {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: rem(30px);
        }
    }
}
/deep/ {
    .content-wrapper {
        .page__head {
            min-height: auto;
        }
    }
    @media screen and (max-width: 1199px) {
        .content-wrapper {
            .page__head {
                min-height: 120px;
            }
        }
        .page-banner {
            --h: 627px;
        }
    }
    @media screen and (max-width: 991px) {
        .sidenav-wrapper {
            .right-col {
                .page__title {
                    padding-top: rem(52px);
                    span {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .page-banner {
            --h: 572px;
        }
    }
    @media screen and (max-width: 767px) {
        .page-banner {
            --h: 420px;
        }
        .content-wrapper {
            .page__head {
                min-height: 130px;
            }
        }
        .page-contents {
            padding-top: 0;
        }
    }
}

// ar style

.ar {
    .sidenav-wrapper {
        /deep/.right-col {
            .page__title {
                span {
                    margin-right: rem(12px);
                    margin-left: 0;
                    left: -67px;
                    right: auto;
                }
                @media screen and (max-width: 991px) {
                    padding-top: 16px;
                }
                @media screen and (max-width: 767px) {
                    span {
                        left: -50px;
                        right: auto;
                    }
                }
            }
            @media screen and (max-width: 991px) {
                padding-top: 58px !important;
            }
        }
    }
    .messages {
        margin-top: rem(28.8px);
        @media screen and (max-width: 1450px) {
            margin-top: rem(25.6px);
        }
        @media screen and (max-width: 1199px) {
            margin-top: rem(57.6px);
        }
        @media screen and (max-width: 991px) {
            margin-top: 0;
        }
        &__row {
            padding-right: rem(32px);
            padding-left: 0;
        }
        &__list {
            margin-left: rem(30px);
            margin-right: 0;
            &--search {
                &:before {
                    transform: translateY(-50%) rotate(90deg);
                    right: -18px;
                    left: auto;
                }
                &:after {
                    right: 0;
                }
                .form-control {
                    padding-right: 25px;
                    padding-left: 1.375rem;
                }
            }
        }
    }
}
</style>
