<template>
    <div>
        <div
            class="messages__userMessage"
            v-for="(user, key) in users"
            :key="key"
            :class="[
                selectedChat && selectedChat.id == user.id ? 'active' : '',
            ]"
        >
            <div class="messages__user">
                <div class="messages__user--image">
                    <img
                        loading="lazy"
                        v-if="user.photo"
                        src="@/assets/images/user-img.png"
                        alt=""
                    />
                    <img
                        loading="lazy"
                        v-else
                        src="@/assets/images/svg/person.svg"
                        alt=""
                    />
                </div>

                <span
                    v-if="user.unread_count > 0"
                    class="messages__user--status"
                ></span>
            </div>
            <div class="messages__userDetail">
                <p class="name">{{ user.display_name }}</p>
                <p>{{ user.title }}</p>
                <!-- <p class="time">09:00 AM</p> -->
            </div>
            <a
                href="javascript:void(0)"
                @click="$emit('click', user.id)"
                class="abs-link"
            ></a>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        users: Array,
        selectedChat: Object,
    },
}
</script>

<style lang="scss" scoped>
.messages {
    &__userMessage {
        padding: rem(20px) 0;
        border-bottom: 1px solid #fdffd5;
        @include flex(center, flex-start);
        flex-wrap: nowrap;
        position: relative;
        @media screen and (min-width: 1025px) {
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .active {
        opacity: 0.7;
    }
    &__user {
        position: relative;
        width: rem(65px);
        height: rem(65px);
        margin-right: rem(10px);
        &--image {
            width: rem(65px);
            height: rem(65px);
            border-radius: 100%;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        &--status {
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background: #e4d700;
            right: rem(4px);
            top: rem(4px);
        }
    }
    &__userDetail {
        position: relative;
        flex: 1;
        p {
            font-size: rem(16px);
            color: #323232;
            line-height: 1;
            margin: 0;
            &.name {
                color: #4a1885;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: rem(14px);
            }
            &.time {
                font-size: rem(13px);
                color: #323232;
                font-weight: 600;
                margin-left: rem(15px);
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

// ar style
.ar {
    .messages {
        &__user {
            margin-left: rem(10px);
            margin-right: 0;
            &--status {
                left: rem(4px);
                right: auto;
            }
        }
        &__userDetail {
            p {
                &.time {
                    margin-right: rem(15px);
                    margin-left: 0;
                    left: 0;
                    right: auto;
                }
            }
        }
    }
}
</style>
